import { graphql } from 'gatsby'
import { ErrorComponent } from '../components/ErrorComponent'

const NotFoundPage = ({ data }) => {
  if (typeof window !== 'undefined' && window.originalPath404) {
    // fix the url of the 404 page. If we dont have this, the url will be /en/404
    window.history.replaceState(null, null, window.originalPath404)
  }
  return <ErrorComponent {...data.content.page} />
}

export const query = graphql`
  query NotFoundPageTemplate($locale: String!) {
    content: contentful {
      page: genericPage(id: "4tbs2kmu5Pm7CbAIeWOtYN", locale: $locale) {
        hero: heroLayout {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        contentRows: contentRowsCollection(limit: 4) {
          items {
            ...ContentRows
          }
        }
      }
    }
  }
`

export default NotFoundPage
